import {
  MantineProvider as BaseMantineProvider,
  type MantineProviderProps,
  type DefaultMantineColor,
  type Tuple
} from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { type FC } from 'react'

type ExtendedCustomColors =
  | 'brandBlue'
  | 'brandGreen'
  | 'brandOrange'
  | 'brandPink'
  | 'brandYellow'
  | 'transparent'
  | DefaultMantineColor

declare module '@mantine/core' {
  // noinspection JSUnusedGlobalSymbols
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}

export const MantineProvider: FC<MantineProviderProps> = ({ children }) => (
  <BaseMantineProvider
    withGlobalStyles
    withNormalizeCSS
    theme={{
      colors: {
        // Original brand colors were fed into https://smart-swatch.netlify.app/
        // Original brand color: #3FBFB8
        brandBlue: [
          '#defafc',
          '#bee9ee',
          '#9bdce0',
          '#76d2d3',
          '#52c6c4',
          '#39ada6',
          '#288586',
          '#195b61',
          '#05343b',
          '#001216'
        ],
        // Original brand color: #ABD37C
        brandGreen: [
          '#eefbe4',
          '#d6edc3',
          '#bfdf9f',
          '#a9d279',
          '#8ac554',
          '#69ac3b',
          '#4b862d',
          '#305f1f',
          '#193910',
          '#011400'
        ],
        // #F7A245
        brandOrange: [
          '#fff2de',
          '#fed9b2',
          '#fbc284',
          '#f8a954',
          '#f59125',
          '#db770d',
          '#ab5d07',
          '#7a4203',
          '#4a2700',
          '#1d0b00'
        ],
        // Original brand color: #F170A6
        brandPink: [
          '#ffe5f6',
          '#fbb7d9',
          '#f58abb',
          '#ef5c9a',
          '#ea3089',
          '#d0177c',
          '#a3106a',
          '#750854',
          '#480338',
          '#1d0018'
        ],
        // Original brand color: #F2E10B
        brandYellow: [
          '#fffddc',
          '#fcf7b0',
          '#f9f182',
          '#f7eb52',
          '#f5e623',
          '#dccc0a',
          '#ab9f03',
          '#7a7100',
          '#494400',
          '#191700'
        ],
        transparent: [
          '#00000005',
          '#00000010',
          '#00000015',
          '#00000020',
          '#00000025',
          '#00000030',
          '#00000035',
          '#00000040',
          '#00000045',
          '#00000050'
        ]
      },
      loader: 'bars',
      primaryColor: 'blue',
      primaryShade: {
        light: 4,
        dark: 8
      },
      globalStyles: () => ({
        'html, body': {
          overscrollBehaviorX: 'none'
        }
      })
    }}
  >
    <ModalsProvider>
      <Notifications position='bottom-center' />
      {children}
    </ModalsProvider>
  </BaseMantineProvider>
)
